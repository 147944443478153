import { uniqBy } from 'lodash-es';
import { assign, createMachine } from 'xstate';

import resourceRoutes from '~/constants/resourceRoutes';
import { clientFetch } from '~/helper/helper.client';

import type { ApiResponse, Group, Pagination, SlimGroup } from 'types';

interface RailGroup extends SlimGroup {
  addedFromMatches?: boolean;
}

type MachineContext = {
  groups: RailGroup[];
  pagination: Pagination;
  loading: boolean;
  error: boolean;
  groupIDToRemove: number;
};

type InvokeEvent = {
  type: string;
  data: ApiResponse<Group[]>;
};

type SingleGroupEvent = {
  type: string;
  group: Group;
};

const fetchGroups = async (page: undefined | string): Promise<Group[] | SlimGroup[]> => {
  const qs = new URLSearchParams([
    ['active', 'true'],
    ['page', page || '1'],
    ['slim', 'true'],
  ]);
  return clientFetch(`${resourceRoutes.myGroups}?${qs}`).then(response => response.json());
};

const onLoadMoreGroups = (context: MachineContext, event: InvokeEvent) => {
  if (context.groups.some((g: RailGroup) => g.addedFromMatches)) {
    return uniqBy(
      [...context.groups, ...event.data.data].sort((a, b) => {
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
      }),
      'id',
    );
    // sort groups by updated by and uniqueness
  }
  return [...context.groups, ...event.data.data];
};

const onAddGroup = (context: MachineContext, event: SingleGroupEvent) => {
  const groupInList = context.groups.find(g => g.id === event.group.id);
  // reject if group is already in the list and hasn't been updated
  if (groupInList && groupInList.updated_at === event.group.updated_at) {
    return Promise.reject();
  }
  // otherwise add the group or resort the group list
  return Promise.resolve(event);
};

const onEditGroup = (context: MachineContext, event: SingleGroupEvent) => {
  return Promise.resolve(event);
};

const editGroup = (context: MachineContext, event: { data: { group: Group } }) => {
  const { group } = event.data;
  const index = context.groups.findIndex(g => g.id === group.id);
  if (index > -1 && group) context.groups[index] = group;
  return context.groups;
};

const sortGroups = (context: MachineContext, event: { data: { group: Group } }) => {
  const { group } = event.data;
  const newGroupUpdated = new Date(group.updated_at).getTime();
  const newGroup = { ...group, addedFromMatches: true };

  const oldGroupInList = context.groups.find(g => g.id === group.id);
  const closestGroup = context.groups.find(g => {
    const groupUpdated = new Date(g.updated_at).getTime();
    return newGroupUpdated > groupUpdated;
  });
  // the order is good don't change anything
  if (oldGroupInList && closestGroup && oldGroupInList.id === closestGroup.id) return context.groups;

  if (oldGroupInList) context.groups.splice(context.groups.indexOf(oldGroupInList), 1);
  if (closestGroup) context.groups.splice(context.groups.indexOf(closestGroup), 0, newGroup);
  else context.groups = [...context.groups, newGroup];
  return context.groups;
};

export const groupsMachine = createMachine(
  {
    id: 'groups',
    // Initial state
    initial: 'init',
    predictableActionArguments: true,
    context: {
      groups: [] as RailGroup[],
      pagination: {} as Pagination,
      loading: true,
      error: false,
      groupIDToRemove: 0,
    },
    // States
    states: {
      init: {
        invoke: {
          id: 'getGroups',
          src: () => fetchGroups(undefined),
          onDone: {
            target: 'success',
            actions: assign<MachineContext, InvokeEvent>({
              groups: (_, event) => event.data.data,
              pagination: (_, event) => event.data.metadata.pagination as Pagination,
              loading: () => false,
            }),
          },
          onError: {
            target: 'failure',
            actions: assign<MachineContext, InvokeEvent>({
              groups: context => context.groups,
              pagination: context => context.pagination,
              error: () => true,
              loading: () => false,
            }),
          },
        },
      },
      loadMore: {
        entry: 'setLoading',
        invoke: {
          id: 'loadMoreGroups',
          src: context => fetchGroups((context.pagination.current_page + 1).toString()),
          onDone: {
            target: 'success',
            actions: assign<MachineContext, InvokeEvent>({
              groups: onLoadMoreGroups,
              pagination: (_, event) => event.data.metadata.pagination as Pagination,
              loading: () => false,
            }),
          },
          onError: {
            target: 'failure',
            actions: assign<MachineContext, InvokeEvent>({
              groups: context => context.groups,
              pagination: context => context.pagination,
              error: () => true,
              loading: () => false,
            }),
          },
        },
      },
      addGroup: {
        invoke: {
          id: 'addGroup',
          src: (context, event) => onAddGroup(context, event as SingleGroupEvent),
          onDone: {
            target: 'success',
            actions: assign<MachineContext, { type: string; data: { group: Group } }>({
              groups: (context, event) => sortGroups(context, event),
              pagination: context => context.pagination,
            }),
          },
          onError: {
            // in this case we need onError for the invoke syntax but its not really an error per say
            target: 'success',
          },
        },
      },
      editGroup: {
        invoke: {
          id: 'editGroup',
          src: (context, event) => onEditGroup(context, event as SingleGroupEvent),
          onDone: {
            target: 'success',
            actions: assign<MachineContext, { type: string; data: { group: Group } }>({
              groups: (context, event) => editGroup(context, event),
            }),
          },
          onError: {
            // in this case we need onError for the invoke syntax but its not really an error per say
            target: 'success',
          },
        },
      },
      removeGroup: {
        invoke: {
          id: 'removeGroup',
          src: (_, e) => Promise.resolve(e),
          onDone: {
            target: 'success',
            actions: assign<MachineContext, { type: string; data: { group: Group } }>({
              groups: (context, event) =>
                context.groups.filter(g => g.id !== event.data?.group?.id && g.id !== context.groupIDToRemove),
              pagination: context => context.pagination,
              groupIDToRemove: 0,
            }),
          },
          onError: {
            // in this case we need onError for the invoke syntax but its not really an error per say
            target: 'success',
          },
        },
      },
      removeGroupOnNextNavigation: {
        entry: 'saveGroupIDToRemove',
        on: {
          REMOVE: 'removeGroup',
          SUCCESS: 'success',
        },
      },
      success: {
        // groupIDToRemove only matters when we're in that state so resetting it here to be safe
        entry: 'deleteGroupIDToRemove',
        on: {
          ADD: 'addGroup',
          EDIT: 'editGroup',
          LOAD_MORE: 'loadMore',
          REMOVE: 'removeGroup',
          REMOVE_NEXT_NAVIGATION: 'removeGroupOnNextNavigation',
        },
      },
      // TODO: when we figure out error handling see if we want to add retry logic
      failure: {
        on: {
          RETRY: {
            target: 'init',
          },
        },
      },
    },
  },
  {
    actions: {
      setLoading: context => {
        context.loading = true;
        context.error = false;
      },
      saveGroupIDToRemove: (context, event) => {
        context.groupIDToRemove = event.groupID;
      },
      deleteGroupIDToRemove: context => {
        context.groupIDToRemove = 0;
      },
    },
  },
);
