import { useInterpret } from '@xstate/react';
import { createContext, useMemo, useContext } from 'react';

import { groupsMachine } from './groups.machine';

import type { InterpreterFrom } from 'xstate';

export const GroupsStateMachineContext = createContext({ groupsService: {} as InterpreterFrom<typeof groupsMachine> });

export const GroupsStateMachineProvider = ({ children }: { children: React.ReactNode }) => {
  const groupsService = useInterpret(groupsMachine);
  const providerValue = useMemo(
    () => ({
      groupsService,
    }),
    [groupsService],
  );

  return <GroupsStateMachineContext.Provider value={providerValue}>{children}</GroupsStateMachineContext.Provider>;
};

export const useGroupsStateMachine = () => {
  return useContext(GroupsStateMachineContext);
};
